<template>
    <v-container>

      <div class="hobbie-title">
        Close Professional Dynamic
      </div>
      
      <v-row>
        <p class="hobbie-text" >  
          I am a cooperative and very outgoing person, I love being part of work teams
          and <span>contact with stakeholders</span> in order to integrate them into the development process and
          obtain solutions best suited to their needs. <br/>
          Currently I dedicate myself to the design and <span>interconnection of applications</span> in a professional way, although
          also creating technology as freelance to elevate people and companies. <br/>
          Looking to the future I intend to <span>create a community</span> thanks to the contribution of open code projects
          and the diffusion of my experience and points of view on the IT sector in social media.
        </p> 
      </v-row>

    </v-container> 
</template>

<style scoped>

  .hobbie-title{
    font-size:40px;  
    line-height: 40px;
    font-weight:bold; 
    text-align:center; 
    font-family: Quincy-Medium;
    color: #e7b70a;
    word-spacing: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  .hobbie-text{
    font-family:"Rubik-Light";
    font-size:25px; 
    text-align:center;
    margin: 0px 40px 0px 40px;
    font-weight: bold;
    color: #006071;
  }

  .hobbie-text span{
    font-family: Rubik-Medium;
  }

  .top-shape{
    height: 50%;
  }

  .bot-shape{
    height: 50%;
  }
</style>

<script> 
export default {

  data(){
    return{
      icons: [
        {
          name: "Analysis",
          image: 'Analysis.png',
        },
        {
          name: "Backend",
          image: 'Backend.png',
        },
        {
          name: "Frontend",
          image: 'Frontend.png',
        },
        {
          name: "Comunication",
          image: 'Comunication.png',
        },
        {
          name: "Growth",
          image: 'Growth.png',
        },
        {
          name: "Lifestyle",
          image: 'Lifestyle.png',
        }
      ]
    }
  }
}
</script>

<style>

</style>