<template>
    <div class="landing-container">
     
        <v-row justify="center" class="landing-row">
            <v-col class="landing-left-col col-xs-12" sm="12"  md="6" lg="6">
                <v-img
                eager
                contain
                aspect-ratio="1.5"
                class="landing-image"
                :src= "require(`@/assets/photos/fOTO.png`)" 
            ></v-img>
            </v-col>

            <v-col class="landing-right-col" xs="12" sm="12" md="6" lg="6" >
                <div class="tagline">
                     <vue-typer  
                        class = "tagline-hello"
                        :text='["Hi 👋🏽, I am"]'
                        :repeat='Infinity'
                        :shuffle='false'
                        initial-action='typing'
                        :pre-type-delay='70'
                        :type-delay='100'
                        :pre-erase-delay='5000'
                        :erase-delay='100'
                        erase-style='backspace'
                        :erase-on-complete='false'
                        caret-animation='blink' 
                    ></vue-typer> 

                    <h1 class="tagline-name">
                        Oscar Casado
                    </h1> 

                    <h1 class="tagline-title">
                        Fullstack developer
                    </h1> 
                </div>

                <div class="landing-buttons-container">
                    <v-btn 
                        rounded
                        plain
                        v-on:click="scrollToElement({behavior: 'smooth'} , 'contact')"  
                        class="contact-button">
                        Contact me!
                    </v-btn>

                    <v-btn 
                        rounded
                        class="resume-button">
                        <a class="resume-button-link"
                            :href=  " '/files/CV.pdf' "  download = "CV.pdf">
                            Resume
                        </a>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        
        
    </div> 

</template>

<style >
   @media (max-width: 650px) {
        .landing-image{
            aspect-ratio: 1.5 !important;
        }
   }

    @media (max-width: 960px) {
        .landing-image{
            aspect-ratio: 3;
        }

        .tagline{
            text-align: center !important;
        }

        .landing-buttons-container{
            display: flex;
            flex-direction: column;
        }

        .contact-button{
            margin: 0 10% 5px 10% !important;
        }

        .resume-button{
            margin: 20px 10% 0 10%;
        }
    }
  
    
    .landing-container { 
        height:100vh;
        width: 100%;   
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        overflow: hidden;
    }

    .landing-row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    .col{
        flex-basis: unset;
    }

    .landing-left-col{
        display:flex;
        align-items: center;
    }

    .landing-image{
        transition: transform .6s;
    }

    .landing-image:hover{
        transform: scale(1.1);
    }

    .landing-right-col{

    }

    .tagline{
        text-align: left;
    }

    .tagline-hello{
        font-family: Rubik-Medium;
        font-size: 35px;
        line-height: 35px;
    }

    .custom.char {
        color: #094f59 !important;
    }

    .tagline-name{
        font-size: 8vw;
        line-height: 7vw;
        margin-top: 20px;
        color: #094f59;
    }

    .tagline-title{
        margin-top: 20px;
        font-family: Quincy-Medium;
        color: #008894;
        transition: text-decoration-color 300ms;
        font-size: 5vw;
        line-height: 4vw;
    }

    .tagline-title:hover {
        text-decoration-color: #094f59;
    }

    .landing-buttons-container{
        margin-top: 20px;
    }

    .contact-button{
        background-color: #F2CF4F !important;
        padding: 20px !important;
        font-size: 20px !important;
        margin: 0 20px 0px 0;

        border: 2px solid transparent;
    }

    .contact-button:hover{
        box-shadow: 0px 0px 5px 5px #F2CF4F ;
    }

    .resume-button{
        background-color: transparent !important;
        border: 2px solid #008894;
        padding: 20px !important;
        font-size: 20px !important;

        color: #008894;
    }

    .resume-button-link{
        text-decoration:none;
        color:#008894 !important;
    }

</style>

<script>
    import {VueTyper} from 'vue-typer'

    export default { 
        components:{
                VueTyper
        },

        methods: {  
            scrollToElement(options, focus) {
                const el = document.getElementsByClassName(focus)[0]; 
                if (el) {
                    el.scrollIntoView(options);
                }
            }  
        }   
    }
</script>