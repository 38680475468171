<template> 
  <v-container>
    <v-row
      class="works"
      >
      <v-col
        class="project-slot"
        
        v-for="proyect in proyects" 
        :key="proyect.name"  
        :id="proyect.name" 
        >  
        
        <v-card  
          class="project-card d-flex flex-column " 
        >
          <v-img 
            :src= "require(`@/assets/illustrations/${proyect.image}`)"  
            :class=" ['project-image']"
            eager  
          ></v-img>

          <v-card-title class="project-title">{{proyect.name}}</v-card-title>

          <div class="buttons-container">
            <v-btn class="project-button" icon
                :href=proyect.url target="_blank"  
              >
              <v-icon color="#094f59" size="40px" >mdi-github</v-icon>
            </v-btn>

            <!-- <v-btn class="project-button" icon
                target="_blank"  
              >
              <v-icon size="40px" >mdi-open-in-new</v-icon>
            </v-btn> -->
          </div>

          <v-card-text style="padding-top:0px;">
            <v-chip-group  
              column
              class="project-tags"
            >
              <v-chip class="proyect-chip" v-for="tech in proyect.technologies" :key="tech.name">
                {{tech.name }}   
              </v-chip>  
            </v-chip-group>

            <div class="project-description">{{proyect.description}}</div>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
  
    

 
</template>

<style>
  .works{ 
    margin: 12px 12px 12px 12px;
  }

  .project-slot{
    width: 400px; 
  }

  .project-card{ 
    margin: 20px 20px 20px 20px;
    padding: 15px 15px 15px 15px;
    border-radius:10px !important; 
    text-align: center;
  } 

  .project-image{
    min-height: 370px;
    max-height: 370px;
    border-radius:10px; 
  }
  
  .project-title{ 
    padding: 5px 5px 5px 5px !important;
    font-size: 40px !important;
    font-weight: bold !important;
    margin-top: 10px;
    justify-content: center;
    color: #006071;
    line-height: 45px;
    word-break: normal;
  }

  .buttons-container{
    line-height: 40px;
  }

  .project-button{
    margin-right: 10px;
  }

  .project-tags{
    margin-top: 5px;
    margin-bottom:15px;
    justify-content: center;
    display: flex;
  }
  
  .v-chip-group--column .v-slide-group__content{
    justify-content: center;
  }

  .project-chip{
    background-color: #252320 !important;
  }

  .project-description{
    font-size: 20px !important;
    color: #006071;
  }
</style>

<script>
export default {
  name: "ProjectsComponent",

  data(){
    return{
      proyects: [
        {
          name: "Block World",
          description: "BlockWorld is a Minecraft inspired background that implements all the basic dynamics of this videogame, such as movements, enemies, actions and much more like SantaClaus mob.",
          url: 'https://github.com/OscarCasadoLorenzo/BlockWorld',
          image: 'Illustration1.jpg',
          technologies: [ 
            {name : "Videogames"},
            {name : "Design patterns"},
            {name : "Testing"},
          ] 
        },
        {
          name: "Image resizer",
          description: "Program to resize images without quality loss, it is based on the bicubic interpolation algorithm.",
          url: 'https://github.com/OscarCasadoLorenzo/BicubicInterpolation',
          image: 'Illustration2.jpg',
          technologies: [
            {name : "C++"},
            {name : "Image manipulation"},
            {name : "Algorithm"}
          ]
        },
        {
          name: "Whyme" ,
          description: "Forum website that consumes my handmade ForumAPI, and in the future will consume StackOverFlow API too.",
          url: 'https://github.com/OscarCasadoLorenzo/whyme',
          image: 'Illustration2.jpg',
          technologies: [
            {name : "VueJS"},
            {name : "Vuetify"},
            {name : "Vue-Router"},
            {name : "VueX"}
          ]
        },
        {
          name: "Forum API",
          description: "API Web service for managing the business logic of a forum, the topics and their users. Implemented with security and validations by the server side.",
          url: 'https://github.com/OscarCasadoLorenzo/ForumAPI',
          image: 'Illustration3.jpg',
          technologies: [ 
            {name : "NodeJS"}, 
            {name : "JWT"},
            {name : "Express"}
          ]
        } 
      ]
    }
  }
}
</script>

<style>

</style>