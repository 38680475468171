<template > 
   <v-app  class="app">
      <loader-component v-if="isLoading"></loader-component>

      <v-main v-if="!isLoading" style="z-index:2;">

        <div  class="cursor"></div>

        <landing-page></landing-page>

        <div class="about">
          <h1 v-scrollanimation class="custom-header">About me</h1>
          <about-component v-scrollanimation></about-component> 
        </div>

        <quote-component v-scrollanimation></quote-component>

        <v-divider class="divider"></v-divider>

        <div class="skills">
          <h1 v-scrollanimation class="custom-header">Knowledges</h1>
          <skills-component style="margin:30px 0 30px 0;" v-scrollanimation></skills-component> 
        </div>
        
        <v-divider class="divider"></v-divider>

        <div class="projects">
          <h1 v-scrollanimation class="custom-header">Projects</h1>
          <projects-component v-scrollanimation></projects-component>
        </div>

        <v-divider class="divider"></v-divider>

        <div class="experience">
          <h1 v-scrollanimation class="custom-header experience">Experience</h1>
          <timeline-component v-scrollanimation></timeline-component>  
        </div>
        
        <div class="contact">
        <contact-component v-scrollanimation></contact-component>
        </div>
        
        <footer-component class="footer"></footer-component> 
      </v-main>
      
    
      <img v-if="!isLoading"
        :src= "require(`@/assets/shapes/FormRound.png`)"  
        class="shape3"
      >

      <img v-if="!isLoading"
        :src= "require(`@/assets/shapes/FormSquare.png`)"  
        class="shape"
      >
      
      <img v-if="!isLoading"
        :src= "require(`@/assets/shapes/FormStar.png`)"  
        class="shape2"
      >

  </v-app>
</template>

<style>
@font-face {
    font-family: "Rubik-Black"; 
    src: local("Rubik-Black"), 
          url(./fonts/Rubik/Rubik-Black.ttf) format("truetype");        
  }

  @font-face {
    font-family: "Rubik-Bold"; 
    src: local("Rubik-Bold"), 
          url(./fonts/Rubik/Rubik-Bold.ttf) format("truetype");        
  }

  @font-face {
    font-family: "Rubik-Medium"; 
    src: local("Rubik-Medium"), 
          url(./fonts/Rubik/Rubik-Medium.ttf) format("truetype");        
  }

  @font-face {
    font-family: "Rubik-Light"; 
    src: local("Rubik-Light"), 
          url(./fonts/Rubik/Rubik-Light.ttf) format("truetype");        
  }
  
   @font-face {
    font-family: "Quincy-Black"; 
    src: local("Quincy-Black"), 
          url(./fonts/Quincy/Quincy-Black.otf) format("truetype");        
  }
  
  @font-face {
    font-family: "Quincy-Medium"; 
    src: local("Quincy-Medium"), 
          url(./fonts/Quincy/Quincy-Medium.otf) format("truetype");        
  }
  
  .app{
    font-family:Rubik-Medium;
    background-color: #E9E5D6!important; 
    color: #006071 !important;
    z-index: 0;
    overflow: hidden;
  }

  .cursor{
    width: 30px;
    height: 30px;
    border: 3px solid #606887;
    border-radius: 50%;
    position: fixed;
    top:0;
    left:0;
    z-index: 1;
    pointer-events: none;
   
  } 

  .custom-header{
    font-family: Quincy-Black; 
    font-size:50px; 
    text-align: center; 
    color: #094f59
  }

  .divider{
    margin: 20px 30% 20px 30%;
  }

  .before-enter{
    opacity:0;
    transform: translateY(100px);
    transition: all 1s ease-out;
  }

  .enter{
    opacity:1;
    transform: translateY(0px);
  }


  .contact{
    background-color: #094F59;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
  }

  @keyframes spin {
    0%{
      transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
  }

  .shape{
    position: absolute;
    left: -300px;
    top: 65%;
    z-index: 1;
    filter: blur(3px);
    animation: spin 60s linear infinite;
  }

  .shape2{
    position: absolute;
    left: -400px;
    top: 20%;
    z-index: 1;
    transform: rotate(25deg);
    animation: spin 60s linear infinite;

    filter: blur(3px);
  }

  .shape3{
    position: absolute;
    right: -400px;
    top: 40%;
    z-index: 1;
    transform: rotate(35deg);
    animation: spin 60s linear infinite;

    filter: blur(3px);
  }

</style> 

<script>
import LandingPage from './components/LandingPage.vue'
import QuoteComponent from './components/QuoteComponent.vue'
import TimelineComponent from './components/TimelineComponent.vue'
import AboutComponent from './components/AboutComponent.vue'
import SkillsComponent from './components/SkillsComponent.vue' 
import ProjectsComponent from './components/ProyectsComponent.vue'
import ContactComponent from './components/ContactComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import LoaderComponent from './components/LoaderComponent.vue'


export default {
  name: "App",

  components:{
    LandingPage,
    QuoteComponent,
    TimelineComponent,
    AboutComponent,
    SkillsComponent,
    ProjectsComponent,
    ContactComponent,
    FooterComponent,
    LoaderComponent
},

  data() {
    return {  
      isLoading:true
    };
  },

  methods: {  
    moveCursor(event) { 
      var cursor = document.querySelector('.cursor');
      //e.pageX & e.pageY returns the location of the cursor in the screen
      cursor.setAttribute("style", "top:" + (event.clientY - 15) +"px; left:" + (event.clientX - 15) +"px;");  
    }
  },

  mounted(){
    setTimeout(() => {
      this.isLoading = false
    }, 9000)
    document.addEventListener("mousemove", this.moveCursor);
  }
};
</script>
