<template>
  <v-container>
    <v-timeline align-top>
      <v-timeline-item v-for="(job, index) in trayectory" :key="job.Title" class="text-right">
        <div class="description">
          <div v-if="index % 2 == 0">
            <span class="description-title">{{job.title}}</span>
            <p class="description-body">{{job.description}}</p>
          </div>
          <div v-else>
            <span class="enterprise-title">{{job.enterprise}}</span> 
            <span class="enterprise-date">{{job.initialDate}} to {{job.finalDate}}</span>
          </div>
          
        </div>

        <div slot="opposite" class="enterprise">
            <div v-if="index % 2 == 0">
            <span class="enterprise-title">{{job.enterprise}}</span> 
            <span class="enterprise-date">{{job.initialDate}} to {{job.finalDate}}</span>
          </div>
          <div v-else>
            <span class="description-title">{{job.title}}</span>
            <p class="description-body">{{job.description}}</p>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>


<style>

.enterprise{
  text-align: left;
}

.v-timeline-item__opposite{
  align-self: stretch;
}

.enterprise-title{
  display: block;
  font-size: 30px;
  text-align: right;
  color: #008894;
}

.enterprise-date{
  display: block;
  text-align: right;
  opacity: 60%;
  font-family: Quincy-Medium;
  font-size: 20px;
}

.description{
  text-align: justify;
}

.description-title{
  font-size: 30px;
    color: #006071;
}

.description-body{
  font-family: Rubik-Light;
  font-weight: bold;
  font-size: 20px;
}

.v-application .primary {
  background-color: #094F59 !important;
  border-color: white !important;
}

</style>

<script> 
  export default {
    data(){
      return{
        trayectory : [
          {
            title: 'Backend Developer',
            enterprise: 'Bosonit',
            initialDate: 'July 2022',
            finalDate: 'Present',
            description: 'With my team, we explored the possibilities of SpringBoot developing APIs, using auto-generation of databases through JPA(Hibernate), dependency injection and hexagonal architecture.'
          },
          {
            title: 'Fullstack Developer & Designer',
            enterprise: 'Freelance',
            initialDate: 'June 2022',
            finalDate: 'Present',
            description: 'I currently develop applications for clients as a freelancer. This allows me to manage projects and be present in all phases of software development, apply agile methodologies and experiment with different technologies.'

          },
          {
            title: 'Informatic Ingenieer',
            enterprise: 'University of Alicante',
            initialDate: 'September 2018',
            finalDate: 'December 2022',
            description: 'My training has provided me with an overview of the different architectures, methodologies and tools when developing projects both individually and as part of a team. It has also allowed me to be critical when assessing different solutions, find their strengths and make coherent and informed decisions.'

          }
        ]
      };
    }
  }
</script>
