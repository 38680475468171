<template>
    <v-row class="footer">
        <v-footer width="100%" class="footer" >
            <v-row  style="width:100%; justify-content:center; "  class="pa-5 ">
                <a class="scroll-index" v-on:click="scrollToElement({behavior: 'smooth'} , 'about')">About me</a>
                <a class="scroll-index" v-on:click="scrollToElement({behavior: 'smooth'} , 'skills')">Knowledges</a>
                <a class="scroll-index" v-on:click="scrollToElement({behavior: 'smooth'} , 'projects')"> Projects</a> 
                <a class="scroll-index" v-on:click="scrollToElement({behavior: 'smooth'} , 'contact')">Contact</a>
            </v-row>

            

            <h4 style="text-align:center; width:100%"  class="pa-2">
                © OSSO. All rights reserved.
            </h4>
        </v-footer>
    </v-row>
</template> 

<style scoped>
    .footer{
        color:#E9E5D6;
        background-color: #094F59 !important;
        backdrop-filter: blur(50px);
    }

    .scroll-index{
        color:#E9E5D6;
        padding: 0 10px 0 10px;
    }
</style> 

<script>
export default {

    methods: {  
        scrollToElement(options, focus) {
            const el = document.getElementsByClassName(focus)[0]; 
            if (el) {
                el.scrollIntoView(options);
            }
        }  
    }   
}
</script>

 