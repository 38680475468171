<template>

  <v-row justify="center" class="mt-6 pt-6">
    <v-col xs="12" sm="12" lg="4" md="4" class="meet-column">
      <h3 class="meet-title">Let's <span class="meet-span">meet </span>!</h3>
      <p class="meet-paragraph">Book a free session where we will discuss your proyect, your issues and how to solve
        them.</p>
    </v-col>

    <v-col xs="12" sm="12" lg="5" md="5">

      <form ref="form" class="contact-container mx-6 mb-6" @submit.prevent="sendEmail">

        <div id="contact-name-input" class="input-container column">
          <label>Name:
            <span class="input-error" v-if="!nameIsValid && this.checkFormErrors">Please enter your name</span>
          </label>
          <input id="contact-name-input-field" class="input-field" name="user_name" v-model="form.name">
        </div>

        <div id="contact-email-input" class="input-container column">
          <label>Email:
            <span class="input-error" v-if="!emailIsValid && this.checkFormErrors">Please enter a valid email
              address</span>
          </label>
          <input id="contact-name-input-field" class="input-field" name="user_email" v-model="form.email">
        </div>
        <label class="spam-advertisement">
          <v-icon color="grey">mdi-lock-check</v-icon>
          I won't send you spam
        </label>

        <div id="contact-message-input" class="input-container column">
          <label>Message:
            <span class="input-error" v-if="!msgIsValid && this.checkFormErrors">Please enter a valid email
              address</span>
          </label>

          <textarea id="contact-message-input-field" class="input-field" name="message" v-model="form.msg" rows="8">
          </textarea>
        </div>

        <v-card-actions class="networks-container">
          <div class="rrss-icons">
            <v-btn class="mr-3" icon v-for="red in rrss" :key="red.titulo" :href=red.url target="_blank">
              <v-icon size="40px" color="#E9E5D6"> {{red.icono}}</v-icon>
            </v-btn>
          </div>
          <v-btn v-if="!successAlertComputed && !errorAlertComputed " type="submit" rounded class="submit-button"
            value="Send">
            Send it!
          </v-btn>

          <v-alert v-model="successAlertComputed" type="success" class="alert" dense>Email has been sent succesfully!
          </v-alert>

          <v-alert v-model="errorAlertComputed" type="error" class="alert" dense>Try again later :(</v-alert>
        </v-card-actions>
      </form>
    </v-col>
  </v-row>


</template>

<style>
@media (max-width: 960px) {
  .meet-column {
    text-align: center;
  }

  .cursor {
    display: none;
  }
}

@media (max-width: 570px) {
  .rrss-icons {
    display: none;
  }
}

.meet-title {
  color: #E9E5D6;
  font-size: 120px;
  line-height: 120px;
}

.meet-span {
  color: #F2CF4F;
  font-size: 130px;
  font-family: Quincy-Black;
}

.meet-paragraph {
  color: #E9E5D6;
  font-size: 40px;
  padding: 10px 10px 10px 10px;
}

.contact-container {
  border-radius: 30px;
  padding: 20px 20px 20px 20px;
  border: 1px solid #E9E5D6;
}

.column {
  display: flex;
  flex-direction: column;
}

.input-container {
  width: 100%;
  box-sizing: border-box;
  background: #E9E5D6;
  color: #ACACAC;
  border-radius: 13px;
  padding: 7px;
  padding-left: 15px;
}

.input-error {
  color: red;
}

.input-field {
  outline: none;
}

#contact-name-input {
  margin-bottom: 20px;
}

.spam-advertisement {
  color: #E9E5D6;
}

#contact-email-input{
  margin-bottom: 5px;
}

#contact-message-input {
  margin-top: 20px;
}

#contact-message-input-field {
  resize: none;
}

.networks-container {
  margin-top: 20px;
  position: relative;
  height: 40px;
}

.submit-button {
  position: absolute;
  right: 10px;
  background-color: #F2CF4F !important;
}

.alert {
  position: absolute !important;
  right: 10px;
  margin-bottom: 0px !important;
}

</style>



<script>
import emailjs from '@emailjs/browser';
import config from '../../config.js';

export default {
  methods: {
    submitFormOk() {

    },

    submitFormErr() {

    },

    sendEmail() {
      this.checkFormErrors = true;

      if (this.nameIsValid && this.emailIsValid && this.msgIsValid) {
        emailjs.sendForm(config.EMAILJS_SERVICE_ID, config.EMAILJS_TEMPLATE_ID, this.$refs.form, config.EMAILJS_PUBLIC_KEY)
          .then((result) => {
            console.log('SUCCESS!', result.text);
            this.successAlert = true;
          }, (error) => {
            console.log('FAILED...', error.text);
            this.errorAlert = true;
          });
      }
    }
  },

  computed: {
    nameIsValid() {
      return !!this.form.name && this.form.name.length > 3;
    },

    emailIsValid() {
      var regularExpr = /\S+@\S+\.\S+/;
      return regularExpr.test(String(this.form.email).toLowerCase());
    },

    msgIsValid() {
      return !!this.form.msg
    },

    successAlertComputed() {
      return this.successAlert;
    },

    errorAlertComputed() {
      return this.errorAlert;
    }

  },

  data() {
    return {
      successAlert: false,

      errorAlert: false,

      checkFormErrors: false,

      form: {
        name: null,
        email: null,
        msg: null
      },

      rrss: [
        {
          titulo: 'Linkedin',
          icono: 'mdi-linkedin',
          url: 'https://es.linkedin.com/in/oscarcasadolorenzo'
        },
        {
          titulo: 'Github',
          icono: 'mdi-github',
          url: 'https://github.com/OscarCasadoLorenzo'
        },
        {
          titulo: 'Instagram',
          icono: 'mdi-instagram',
          url: 'https://www.instagram.com/osso.branding/'
        }
      ],
    };
  },
}
</script>

 
